import React from 'react'
import ReactDOM from 'react-dom'

import ReactBnbGallery from 'react-bnb-gallery'

import 'react-bnb-gallery/dist/style.css'


const photos = [
        {
            photo: "https://source.unsplash.com/aZjw7xI3QAA/1144x763",
            caption: "Viñales, Pinar del Río, Cuba",
            subcaption: "Photo by Simon Matzinger on Unsplash",
            thumbnail: "https://source.unsplash.com/aZjw7xI3QAA/100x67",
        },
        {
            photo: "https://source.unsplash.com/c77MgFOt7e0/1144x763",
            caption: "La Habana, Cuba",
            subcaption: "Photo by Gerardo Sanchez on Unsplash",
            thumbnail: "https://source.unsplash.com/c77MgFOt7e0/100x67",
        },
        {
            photo: "https://source.unsplash.com/QdBHnkBdu4g/1144x763",
            caption: "Woman smoking a tobacco",
            subcaption: "Photo by Hannah Cauhepe on Unsplash",
            thumbnail: "https://source.unsplash.com/QdBHnkBdu4g/100x67",
        }
    ];

interface IProps { }

interface IState {
    galleryOpened: boolean,
}

class Example extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = { galleryOpened: false };
        this.toggleGallery = this.toggleGallery.bind(this);
    }

    toggleGallery = () => {
        this.setState(prevState => ({
            galleryOpened: !prevState.galleryOpened
        }));
    }

    render() {
    return (<>
            The goose gallery is still under construction.
            <button onClick={this.toggleGallery}>Open photo gallery</button>
            <ReactBnbGallery
            show={this.state.galleryOpened}
            photos={photos}
            onClose={this.toggleGallery} />
        </>)
    }
}


ReactDOM.render(
  <Example />,
  document.getElementById('root')
);

